body {
    background-color: #F3EBE3;
  }
  
  .container-registro {
    position: absolute;
    display: flex;
    align-items: center; /* Centrar verticalmente */
    height: 100vh;
    background-color: #F3EBE3;
    left: 20%;
  }
  
  .columns-registrarse {
    display: flex;
    width: 280%; /* Ajusta según sea necesario */
   
}
.column-registrarse h1{
    font-family:'Helvetica Neue Medium' ;
    color: black;
    font-size: 1.8rem;
}
     @font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }


  
  .column-registrarse {
    flex: 1; /* Esto asegura que cada columna ocupe una fracción igual del contenedor */
    padding: 20px;
  }
  
  .columna1 {
    background-color: #F3EBE3;
  }
  
  .columna2 {
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    background-color: #F3EBE3; /* Fondo del segundo div */
  }
  
  .input-field {
    margin-bottom: 15px;
  }
  
  .input-field label {
    display: block;
    margin-bottom:5px;
    color: #333;
    font-weight: bold;
    align-items: left;
    text-align: left;
    margin-left: 5%;
  }
  
  .input-field input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 1px !important;

  }
  
  .button.is-primary.registrarse {
    display: block;
    width: 50%;
    padding: 10px;
    background-color: #3273dc;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    top: 0;
    left: 50%;

  }
  
  .button.is-primary.registrarse:hover {
    background-color: #2750a0;
  }
  .input-registrarse{
    background-color: #C0BFD9 !important;

  }
  @font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  .checkbox-label {
    display: flex !important;
    align-items: center !important;
  }
  
  .custom-checkbox {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    height: 20px !important;
    width: 20px !important;
    border: 2px solid #FFA500 !important; /* Color del borde */
    border-radius: 4px !important; /* Esquinas redondeadas */
    margin-right: 10px !important;
    cursor: pointer !important;
    background-color: transparent!important;
  }
  
  .custom-checkbox:checked {
    background-color: #FFA500 !important; /* Color de fondo cuando está marcada */
  }
  
  .conFesaR{
    position: absolute;
    display: flex;
    height: 5.8%;
    top: 58.5%;
    opacity: 0;
    animation: fadeIn 6s forwards;
  }
  
  .somosUnoR {
    position: absolute;
    display: flex;
    height: 18%;
    top: 65%;
    opacity: 0;
    animation: fadeIn 6s forwards;
  
  }
  .logoH{

    height: 60%;
    margin-top: 1.5%;
  }
  .acceso{
    margin-top: 80px;
  }