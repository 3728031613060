.container-img-perro{

    margin-top: 15% !important;
    height: 70%;
    
   
   
   }
   .columns-xiaomi{
       display: flex;
       width: 100% !important;
       height: 100vh;
   
   
   }
   .container-xiaomi{
       position: fixed;
       
       left: 0 !important;
       top: 50% !important;
       height: 100vh !important;
       width: 100% !important;
   
   }
   .columna1{
       flex: 1;
       
   }
   .columna2{
       flex: 1;
   
   
   }
   .perroLogo{
       height: 100%;
   }
   .texto-xiaomi{
       
       height: 40%;
       margin-top: 15%;
       width: 80%;
   }
   .texto-xiaomi h3{
      justify-content: center;
       font-size: 2rem;
       font-family: 'Helvetica Neue Medium';
       color: black;
       margin-bottom: 5%;
   
   }
   .texto-xiaomi p{
       text-align: left;
       font-size: 1.5rem;
       font-family: 'Helvetica Neue';
       color: black;
       padding-left: 10%;
   }
   .buttonperro{
       top: 20% !important;
   }