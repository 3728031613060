.container-img-perro{

 margin-top: 15% !important;
 height: 70%;
 


}
.columns-xiaomi{
    display: flex;
    width: 100% !important;
    height: 100vh;


}
.container-xiaomi{
    position: fixed;
    
    left: 0 !important;
    top: 50% !important;
    height: 100vh !important;
    width: 100% !important;

}
.columna1{
    flex: 1;
    
}
.columna2{
    flex: 1;


}
.perroLogo{
    height: 100%;
}
.texto-xiaomi{
    
    height: 40%;
    margin-top: 19%;
    width: 60%;
}
.texto-xiaomi h3{
    font-size: 2rem;
    font-family: 'Helvetica Neue Medium';
    color: black;
    margin-bottom: 10%;

}
.texto-xiaomi p{
    text-align: left;
    font-size: 1.8rem;
    font-family: 'Helvetica Neue';
    color: black;
}
.buttonperro{
    top: 20% !important;
}