/* src/Styles/Oculus.css */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

.oculus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  font-family: 'Orbitron', sans-serif;
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  transition: background 0.3s ease;
}

.content-overlay:hover {
  background: rgba(0, 0, 0, 0.7);
}

.overlay-content {
  color: white;
  text-align: center;
  max-width: 800px;
  padding: 2rem;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.overlay-content.show {
  opacity: 1;
  transform: translateY(0);
}

.futuristic-text {
  color: #00ffff;
  text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff;
  animation: glow 1.5s ease-in-out infinite alternate;
}

h1.futuristic-text {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p.futuristic-text {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.futuristic-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: linear-gradient(45deg, #00ffff, #ff00ff);
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.futuristic-button:hover {
  transform: scale(1.1);
  box-shadow: 0 0 20px #00ffff, 0 0 40px #00ffff;
}

.model-section {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #000000, #1a1a2e);
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.donation-section {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #fff;
  z-index: 10;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #00ffff, 0 0 10px #00ffff, 0 0 15px #00ffff;
  }
  to {
    text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff;
  }
}
.text{
    font-family: 'Helvetica Neue Medium';
    font-size: 1.3rem;
}
@font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('../assets/fonts/HelveticaNeueBold.otf') format('opentype');
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }