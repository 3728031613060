@font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  .navbar {
    background-color: #FFC07B !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 10%;
  }
  
  .navbar-item {
    width: 42%;
    justify-content: center;
    position: relative;
    font-family: 'Helvetica Neue Medium';
    font-size: 1.1rem;
  }
  
  /* Add underline effect on hover */
  .navbar-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5%;
    margin-left: 30%;
    right: 0;
    background: #ff900d; /* Black underline */
    transition: width 0.4s ease;
    -webkit-transition: width 0.4s ease;
  }
  .navbar-link{
    background-color: transparent !important;
  }
  .navbar-item:hover::after {
    top: 50%;
    width: 40%;
    left: 0;
    background: #ff900d; /* Black underline */
  }
  
  .navbar-item:hover {
    background-color: #f3ebe3 !important;
  }
  
  /* Active item styles */
  .navbar-item.is-active {
    background-color: #f3ebe3 !important;
    color: #ff900d !important;
    
  }
  
  .navbar-item.is-active::after {
    width: 40%;
    left: 0;
    background: #ff900d; /* Active underline */
    top: 50%;
  
  }
  
  /* Styles for submenu items */
  .navbar-dropdown .navbar-item {
    font-family: 'Helvetica Neue Medium';
    font-size: 1rem;
    width: 100%;
    color: black !important;
  }
  
  .navbar-dropdown .navbar-item:hover {
    background-color: #f3ebe3 !important;
  }
  
  .navbar-dropdown .navbar-item.is-active {
    background-color: #f3ebe3 !important;
    color: #ff900d !important;
  }
  
  .navbar-dropdown .navbar-item::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    display: block;
    margin-top: 5%;
    right: 0;
    background: #ff900d; /* Black underline */
    transition: width 0.4s ease;
    -webkit-transition: width 0.4s ease;
  }
  .navbar-item  .has-dropdown:hover{
    color: red !important;
  }
  
  .navbar-dropdown .navbar-item:hover::after,
  .navbar-dropdown .navbar-item.is-active::after {
    top: 40%;
    width: 40%;
    left: -5% !important;
    background: #ff900d; /* Active underline */
  }
  