@font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('../assets/fonts/HelveticaNeueBold.otf') format('opentype');
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .projects-container {
    text-align: center;
    padding: 2rem;
    background-color: #F3EBE3;
    width: 140%;
    min-height: 100vh;
    padding-top: 100px; /* Ajusta este valor según la altura de tu navbar */
  }
  
  .projects-container h1 {
    font-size: 2rem;
    margin-bottom: 4rem;
    font-family: 'Helvetica Neue Medium';
    color: black;
  }
  
  .projects-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .project-card {
    width: 250px;
    margin: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 500ms, transform 500ms;
    margin-right: 70px;
  }
  
  .project-card.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .project-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-card h2 {
    font-size: 1.2rem;
    margin: 1rem 0;
    font-family: 'Helvetica Neue Medium';
    color: black;
  }
  
  .details-button {
    display: inline-block;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    background-color: #FFC07B;
    color: #000;
    text-decoration: none;
    border-radius: 4px;
    font-family: 'Helvetica Neue Medium';
    transition: background-color 0.3s ease;
  }
  
  .details-button:hover {
    background-color: #ff900d;
  }