@font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: black; }
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  @keyframes flipOut {
    from { transform: rotateY(0deg); }
    to { transform: rotateY(-180deg); }
  }
  
  @keyframes flipIn {
    from { transform: rotateY(180deg); }
    to { transform: rotateY(0deg); }
  }
  
  .fraseinferior {
    font-family: 'Helvetica Neue Italic';
    position: absolute;
    font-size: 1.7rem;
    top: 80%;
    margin-left: 5%;
    color: black;
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.15em solid black;
    width: 0;
    animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
    animation-fill-mode: forwards;
  }
  
  .hero-body {
    perspective: 1500px;
    overflow-x: hidden;
    background-color: #F3EBE3;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    height: 100vh;
    margin: 0;
    overflow-y: scroll !important;
    scroll-snap-type: y mandatory !important;
  }
  
  .container {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    padding: 2rem;
    border-radius: 10px;
    min-width: 0;
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    margin: 0;
    scroll-snap-align: start !important;
    transition: transform 1s;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
  
  .pagina1 {
    background-color: #F3EBE3 !important;
    z-index: 3;
  }
  
  .pagina2 {
    background-image: url("../assets/images/Recurso\ 1.webp") !important;
    background-size: cover;
    z-index: 2;
  }
  
  .pagina3 {
    z-index: 1;
  }
  
  .flip-out {
    animation: flipOut 1s forwards;
  }
  
  .flip-in {
    animation: flipIn 1s forwards;
  }
  
  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .button {
    font-size: 1.6rem;
    padding: 0.75rem 1.5rem;
    height: 45px !important;
    width: 200px;
    background-color: #FFC07B !important;
    border-radius: 12px;
    font-family: 'Helvetica Neue Medium';
    position: absolute;
    top: 90%;
    left: 15%;
    transform: translateX(-50%);
  }
  
  .button:hover {
    background-color: #FF900D !important;
  }
  
  .container-frases {
    position: absolute;
    top: 0%;
    left: 45%;
    background-color: transparent !important;
    padding: 20px;
    border-radius: 10px;
    justify-content: center;
    height: 100%;
    width: 40%;
    align-items: center;
    line-height: 0.8;
  }
  
  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .heart-image {
    position: absolute;
    animation: heartbeat 1s infinite;
    height: 70%;
    top: 20%;
    left: 10%;
  }
  
  .logoFesa {
    position: absolute;
    display: flex;
    height: 17%;
  }
  
  .conFesa {
    position: absolute;
    display: flex;
    height: 10.8%;
    top: 22%;
    opacity: 0;
    animation: fadeIn 6s forwards;
  }
  
  .somosUno {
    position: absolute;
    display: flex;
    height: 40%;
    top: 35%;
    opacity: 0;
    animation: fadeIn 6s forwards;
  
  }
  
  .frasesuperior {
    font-family: 'Helvetica Neue Medium';
    position: absolute;
    font-size: 2.5rem;
    top: 6%;
    margin-right: 20%;
    left: 20%;
    color: #FF900D;
  }
  
  .container-texto {
    width: 30%;
  }
  
  .texto1 p {
    font-family: 'Helvetica Neue Regular';
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    position: absolute;
    top: 30%;
  }
  
  .container-texto h3 {
    font-family: "Helvetica Neue Medium";
  }
  
  .logoFesaa {
    position: absolute;
    left: 50%;
    height: 20%;
  }
  
  .texto2 {
    position: absolute;
    top: 83%;
    left: 31%;
    text-align: start;
    font-size: 0.9rem;
    line-height: 1;
    color: black;
    font-family: 'Helvetica Neue Regular';
    color: black;
    font-weight: bold;
  }
  
  .titulo2 {
    padding-left: 15%;
  }
  
  .texto3 {
    position: absolute;
    text-align: start;
    font-size: 1.4rem;
    line-height: 1;
    color: black;
    font-family: 'Helvetica Neue Regular';
    color: black;
    font-weight: bold;
    left: 75%;
    top: 50%;
  }
  
  .titulo3 {
    padding-left: 10%;
  }
  
  .siguiente2 {
    position: absolute;
    top: 85%;
    left: 55% !important;
  }
  
                              