html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden; /* Para evitar el scroll */
  }
  
  .container-donationInformation {
    position: absolute;
    padding: 20px;
    background-color: #F3EBE3; /* Color de fondo de la página */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .titulo1-infomacion {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px; /* Ajusta esto según sea necesario */
  }
  
  .card-container {
    width: 45%; /* Ancho de cada columna */
    perspective: 1000px; /* Necesario para el efecto de flip */
  }
  
  .card {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.8s;
  }
  
  .card:hover {
    transform: rotateY(180deg);
  }
  
  .card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 50vh;
    backface-visibility: hidden;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }
  
  .card-front {
    background-color: #FFFFFF; /* Color de fondo de las columnas */
  }
  
  .card-back {
    background-color: #f0f0f0; /* Fondo de la parte trasera */
    transform: rotateY(180deg);
  }
  
  .card h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 14px;
    line-height: 1.6;
  }
  
  /* Para el resto de tus estilos existentes */
  .containerL {
    position: absolute;
    background-image: url("../assets/images/IMG-20240715-WA0011.jpg");
    background-size: cover;
    background-position: center;
    z-index: 1;
    height: 100vh; /* Asegura que el contenedor ocupe toda la altura de la página */
    left: 0;
    width: 100% !important; /* Ocupa todo el ancho de la pantalla */
    max-width: 100% !important; /* Evita cualquier limitación en el ancho máximo */
  }
  
  .containerL::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(243, 235, 227, 0.9); /* Fondo semitransparente con el color proporcionado */
    z-index: -1; /* Coloca el pseudo-elemento detrás del contenido del contenedor */
  }
  
  .foto1 {
    height: 70%;
    object-fit: cover;
    position: absolute;
    top: 25%;
    left: 3%;
  }
  
  .container-textoL {
    position: relative;
    font-family: 'Helvetica Neue Italic';
    top: 10%;
    left: 1.5%;
    color: black;
    text-align: left;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .container-frase1 {
    position: absolute;
    top: 23.5%;
    left: 37%;
  }
  
  .container-frase2 {
    position: absolute;
    top: 40.5%;
    left: 55%;
    width: 20% !important;
  }
  
  .container-frase3 {
    position: absolute;
    top: 70.5%;
    left: 78%;
    width: 20% !important;
  }
  
  /* Añadir animaciones */
  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
      opacity: 0;
      visibility: hidden;
  
    }
    100% {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
  
    }
  }
  .container-frase1, .container-frase2, .container-frase3 {
    opacity: 0;
    visibility: hidden;
  }
  
  .animated-slide {
    animation: slideIn 1s ease-in-out forwards;
  }
  
  .container-frase1 {
    animation-delay: 0.2s;
  }
  
  .container-frase2 {
    animation-delay: 0.4s;
  }
  
  .container-frase3 {
    animation-delay: 0.6s;
  }
  