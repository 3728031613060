
  
  .container-donationInformation {
    position: absolute;
    padding: 20px;
    background-color: #F3EBE3; /* Color de fondo de la página */
    width: 100% !important;
    height: 100vh;
    max-width: 100% !important;

    top: 90%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: hidden !important;  
}
  
  .titulo1-infomacion {
    font-size: 35px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: 'Helvetica Neue Bold';
    color: black    ;


  }
  
  .columns {
    display: flex;
    align-items: left;
  }
  
  .column {
    width: 600px !important; /* Ancho de cada columna */
    height: 500px !important;
    background-color: #FFFFFF; /* Color de fondo de las columnas */
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-left: 80px;
  }
  
  .column h3 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 30px;
    margin-top: 35px;
    font-family: 'Helvetica Neue Medium';
    color: black;

  }
  
  .column p {

    font-size: 1.3rem;
    line-height: 1.6;
    font-family: 'Helvetica Neue Medium';
    color: black ;
    text-align: left;
    padding-left: 80px;
    color: rgba(74, 74, 74, 0.974);

  }

  
  

@font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  @font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('../assets/fonts/HelveticaNeueBold.otf') format('opentype');
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  .img-cerrada{
    background-image: url('../assets/images/donaCer.jpg');
    background-size: cover;
  }
  .img-abierta{
    background-image: url('../assets/images/donaAbi.jpg');
    background-size: cover;
  }
  .card-title {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 10px;
    width: 106% !important;
    text-align: center;
    font-family: 'Helvetica Neue Medium';
    margin-top: -3%;
    margin-left: -3%;
    border-radius: 15px;
  }
  .card-back{
    font-family: 'Helvetica Neue Medium';
    text-align:justify;
    border-radius: 15px;


  }
  .card-back p{
    font-size: 1.2rem;
    padding: 20px;

  }
  .card-front{
    top: 0 !important;
    border-radius: 15px;

  }
  .card{
    border-radius: 15px;

  }