@font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
.background-modal{
    background-color: red !important;
    width: 400px;
}
.container-modal{
    background-color: #F3EBE3 !important;
    width: 30%;
    position: absolute;
    left: 35%;
    height: 85% !important;
    border-radius: 15px;
}
.label{
    font-family: "Helvetica Neue Medium" !important;
    color: black;
    text-align: left;
    margin-left: 5%;
}
input{
    position: left !important;
    background-color: #F1DCCB !important;
    border-radius: 10px !important;
    height: 15% !important;
    width: 90% !important;
    margin-bottom: 5% !important;
}
.siguiente3{
    position: absolute;
    left: 25%;
    top: 90% !important;
}
.contenedor-fields p{
    font-size: 0.8rem;
    font-family: "Helvetica Neue Italic";
    color: black;

}
.container-modal .xModal{
    position: relative;
    left: 46%;
    color: black;
}

.title-underline{
    color: black;
    background-color: black;
    margin-top: 1px;
    align-content: center;
    width: 90%;
    margin-left: 4%;
}
.title{
    position:relative;
    margin-top: -2%;
}
.container-modal .titulo{
    font-family: "Helvetica Neue Medium" !important;
    color: black;
}