@font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
.custom-box {
    position: relative;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: 300px;
  }
  
  .number-circle {
    position:absolute;
    margin-top: -30%;
    left: 37%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    font-size: 2.1rem;
    color: white;
    font-weight: bold;
  }
  
  .content {
    margin-top:0;
  }
  
  h4 {
    font-family: 'Helvetica Neue Medium';
    margin: 0 !important;
    font-size: 0.9rem !important;
  }
  
 .content p {
    margin: 5px 0 0 0;
    font-size: 0.9em;
    font-family: 'Helvetica Neue Medium' !important;
    color: black !important;
    text-align: left;
    color: rgba(74, 74, 74, 0.974) !important;
    padding-left: 20px;

  }
  