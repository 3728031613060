.donation-box {
    border: 1px solid #000;
    border-radius: 10px;
    text-align: center;
    width: 200px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .donation-box h4 {
    margin-top: 20px !important;
    margin: 0;
    font-size: 1.2rem;
    font-family: 'Helvetica Neue Medium';
    color: black;
  }
  
  .donation-box h1 {
    margin: 0;
    font-size: 3rem;
    margin-top: 60px;
    margin-bottom: 60px;
    font-family: 'Helvetica Neue Medium';
  }
  
  .donate-button {
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.7em;
    font-family: 'Helvetica Neue Medium';
    cursor: pointer;
  }
  
  .donate-button:hover {
    background-color: #F3EBE3 !important;
  }
  
  .container-donation {
    position: absolute;
    background-image: url('../assets/images/personas.jpg');
    background-size: cover;
    background-position: center;
    z-index: 1;
    height: 100vh;
    width: 100% !important;
    max-width: 100% !important;
    left: 0;
  }
  .container-donation::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(243, 235, 227, 0.9); /* Fondo semitransparente con el color proporcionado */
    z-index: -1; /* Coloca el pseudo-elemento detrás del contenido del contenedor */
  }
  
  .container-donation .titulo {
    position: absolute;
    top: 15%;
    left: 40%;
    font-size: 2rem;
    font-family: 'Helvetica Neue Medium';
    color: black;
  }
  
  .contenedor-donaciones1 {
    position: relative;
    top: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
  }
  
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 300px;
    transition: opacity 0.3s ease-in-out;
  }
  
  .carousel-container.transitioning {
    opacity: 0;
  }
  
  .carousel-item {
    flex: 0 0 200px;
    margin: 0 15px;
  }
  
  .informaciones {
    font-family: 'Helvetica Neue Italic';
    position: absolute !important;
    bottom: 10% !important;
    left: 50% !important;
    transform: translateX(-50%);
    font-size: 1.2rem !important;
    text-decoration: underline;
    color: black;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
  }
  
  .carousel-button.prev {
    left: 10%;
  }
  
  .carousel-button.next {
    right: 10%;
  }

  @font-face {
    font-family: 'Helvetica Neue Italic';
    src: url('../assets/fonts/HelveticaNeueLightItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../assets/fonts/HelveticaNeueMedium.otf') format('opentype');
    font-style: normal;
    font-weight: normal;
  }
  
  @font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../assets/fonts/HelveticaNeue Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Estilos existentes ... */

/* Estilos existentes ... */

.donacion-abierta {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
  }
  
  .donacion-abierta h2 {
    font-size: 1.2rem;
    font-family: 'Helvetica Neue Medium';
  }
  
  .donacion-abierta h3 {
    font-size: 1.5rem;
    color: #888;
    margin-bottom: 10px;
    font-family: 'Helvetica Neue Italic';
  }
  
  .monto-input {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .monto-input span {
    font-size: 2rem;
    margin-right: 5px;
  }
  
  .monto-input input {
    font-size: 4rem;
    width: 250px !important;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: right;
    height: 70px !important;
    background-color: white !important;
    border-color: white!important;

  }
  
  .donacion-abierta button {
    background-color: #d3d3d3;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: 'Helvetica Neue Medium';
    color: black;
    margin-top: -10px !important;

  }
  
  .donacion-abierta p {
    font-size: 0.8rem;
    color: #888;
    text-decoration: underline;
    font-family: 'Helvetica Neue Italic';
  }
  
  /* Resto de los estilos existentes ... */
.container-donation p{
    font-family: 'Helvetica Neue Italic';
    margin-top: 18%;
    color: black;
    font-size: 1.2rem;
    text-decoration: underline;

}
.dolar{
    color: black;
    font-family: 'Helvetica Neue Medium';
    font-size: 4rem !important;

}
.donacion-abierta h3 {
    font-size: 2.6rem;
}
